
  import Common from '@/composables/common';
  import { CardComponent } from 'coghent-vue-3-component-library';
  import { defineComponent, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'Introscherm1',
    components: { CardComponent },
    setup() {
      const { t } = useI18n();

      const router = useRouter();
      const nextStep = () => {
        router.push({ name: 'entrance.step2' });
      };

      return { t, nextStep };
    },
  });
