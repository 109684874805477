import mqtt, { IClientPublishOptions, IClientSubscribeOptions } from 'mqtt';
import { useBoxVisiter } from 'coghent-vue-3-component-library';
import Common, { SensorObject } from '@/composables/common';
import { ref } from 'vue';
import { apolloClient } from '@/main';
import { router } from '@/router';

type CallbackItem = {
  name: string;
  callback: Function;
};

export const mqttEnabled = ref<boolean>(true);
export const selectedStory = ref<SensorObject>();
export const selectedStoryBoxId = ref<string>('');
export const selectedStoryId = ref<string>('');
export const wallIsPlaying = ref<boolean>(false);
const callbackList = ref<CallbackItem[]>([]);

const { host, port, endpoint, ...options } = {
  host: 'mqtt-dashboard.com',
  // host: 'mqtt.inuits.local',
  port: 8884,
  endpoint: '/mqtt',
  clean: true,
  connectTimeout: 4000,
  reconnectPeriod: 4000,
  username: '',
  password: '',
};

const connectUrl = `wss://${host}:${port}${endpoint}`;
let client: mqtt.MqttClient;
const subscription: IClientSubscribeOptions = {
  qos: 2,
};
const publication: IClientPublishOptions = {
  qos: 2,
};

export const connectToClient = () => {
  client = mqtt.connect(connectUrl, options);
  client.disconnected;

  client.on('connect', () => {
    console.log('Connection succeeded!');
  });
  client.on('error', (error) => {
    console.log('Connection failed', error);
  });
};

export const doPublish = (_topic: string, message: any) => {
  console.log('DO PUBLISH');
  const { qos } = publication;
  client.publish(_topic, message, { qos });
};

export const doSubscribe = (_topic: string, callback: Function) => {
  callbackList.value.push({ name: _topic, callback: callback });
  console.log('DO SCUBSCRIBE');
  const { qos } = subscription;
  client.subscribe(_topic, { qos }, (error, res) => {
    if (error) {
      console.log('Subscribe to topics error', error);
      return;
    }
  });

  client.on('message', (_topic, message) => {
    const callbackFunction = callbackList.value.find(
      (cb) => cb.name === _topic,
    )?.callback;
    if (callbackFunction) {
      callbackFunction(_topic, message);
    }
  });

  client.on('reconnect', () => {
    doSubscribe(_topic, callback);
  });
};

export const handleSensor = (_topic: string, message: string) => {
  selectedStory.value = {
    topic: _topic,
    id: Number(filterSensorOutOfTopic(_topic)),
    msg: JSON.parse(message.toString()),
  };
};

export const setWallIsPlaying = (_topic: string, message: string) => {
  wallIsPlaying.value = message.toString() == 'true' ? true : false;
};

export const setStory = async (_topic: string, message: string) => {
  const {create, addStoryToVisiter} = useBoxVisiter(apolloClient)
  if (_topic && message) {
    const messageObject = JSON.parse(message.toString());
    if (!messageObject.storyboxId && !messageObject.storyId) {
      window.localStorage.clear();
      router.go(0)
      return
    }

    const localStoryId: string | null = localStorage.getItem('storyId')
    const localBoxVisiter = JSON.parse(localStorage.getItem('visitorCode') as string)
    let boxVisiter = undefined
    if (messageObject.storyId && !localBoxVisiter) boxVisiter = await create(messageObject.storyId)

    if (localBoxVisiter?.code && messageObject.storyId !== localStoryId)
      boxVisiter = await addStoryToVisiter(localBoxVisiter.code, messageObject.storyId)

    if (boxVisiter)
      localStorage.setItem('visitorCode', JSON.stringify(boxVisiter))

  selectedStoryBoxId.value = messageObject.storyboxId;
  selectedStoryId.value = messageObject.storyId;
  window.localStorage.setItem('boxId', selectedStoryBoxId.value);
  window.localStorage.setItem('storyId', selectedStoryId.value);
  router.go(0)
}
};

const restartWallTroughBalena = () => {
  fetch('http://localhost:5011/refresh', {method: "POST"}).then(() => {
    return
  })
}

const filterSensorOutOfTopic = (_topic: string) => {
  const index = _topic.indexOf('/') + 1;
  const lastIndex = _topic.lastIndexOf('/');
  return _topic.substring(index, lastIndex);
};

const createConnection = () => {
  connectToClient();
  doSubscribe('sensors/+/#', handleSensor);
  doSubscribe('story/start', setStory);
  doSubscribe('story/has-started', setWallIsPlaying);
  doSubscribe('wall/reset', restartWallTroughBalena)
};

export const wallInit = () => {
  const paramValue = Common().getUrlParamValue('mqtt');
  if (paramValue === null || (paramValue === 'true' && !client)) {
    console.log('Connect with mqtt');
    mqttEnabled.value = true;
    createConnection();
  } else {
    console.log(`Don't Connect with mqtt`);
    mqttEnabled.value = false;
  }
};
