import { Group, Vector3 } from 'three';
import TextHelper from '@/Three/helper.text';
import HelperText from '@/Three/defaults.helperText';
import Colors from '@/Three/defaults.color';
import SchemaCube, { CubeParams, CubeSchema } from '@/Three/schema.cube';
import GroupHelper from '@/Three/helper.group';
import Images from '@/Three/defaults.images';
import TWEEN from '@tweenjs/tween.js';
import Videos from "@/Three/defaults.videos";
import VideoHelper from "@/Three/helper.video";
import ZoneService, {Zone} from "@/services/ZoneService";

const ScanQR = (
  position: Vector3,
  zoneService: ZoneService
): {
  create: () => Promise<Array<Group>>;
} => {
  const arrow = () => {
    const cube = SchemaCube().CreateImageCube({
      position: new Vector3(position.x + 2.5, position.y - 450, -1),
      params: {
        height: 100,
        width: 200,
        url: Images.startOfSession.arrow,
        color: Colors().pink,
      } as CubeParams,
    } as CubeSchema);
    const targetPosition = new Vector3(position.x - 5, position.y - 450, -1);

    new TWEEN.Tween(cube.position)
      .to(targetPosition)
      .repeat(Infinity)
      .yoyo(true)
      .easing(TWEEN.Easing.Cubic.InOut)
      .start();

    return cube;
  };

  const create = async () => {
    const groups: Array<Group> = [];
    const boxVisiter = localStorage.getItem('visitorCode') as string
    if (!boxVisiter){
      const textWithIcon = await TextHelper().displayTextFromRecordWithVideo(
        HelperText().scanYourTicket(position),
        Colors().white,
        Videos.selectStory,
        new Vector3(zoneService.middleZoneCenter.x, position.y -200, -1),
        new Vector3(1280, 720, 0),
        true
      );
      GroupHelper().AddObjectsTogroups(textWithIcon, groups);
    }

    return groups;
  };

  return {
    create,
  };
};

export default ScanQR;
