export default {
  "touchtable": {
    "codeScreen": {
      "noCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb nog geen code"])},
      "noCodePopUp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen paniek!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan jouw persoonlijke code laten afdrukken via het scherm aan de ingang of via deze <a class='underline font-medium' href='https://data.collectie.gent/?touch=true'>link</a> zonder code door de collectie bladeren. Veel succes!"])}
      },
      "wrongCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze code werd helaas niet gevonden!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het nogmaals"])}
      }
    },
    "header": {
      "basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzamelde werken"])},
      "storyOverviewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek alle verhalen"])},
      "buttons": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht alle verhalen"])},
        "shutdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiten"])}
      }
    },
    "network": {
      "infoCard": {
        "noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit item heeft geen beschrijving"])},
        "buttons": {
          "zoom": {
            "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding vergroten"])},
            "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video bekijken"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediafile bekijken"])}
          },
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan verhalenbox toevoegen"])}
        }
      },
      "basketOverlay": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je verhalenbox is momenteel nog leeg. Je kan werken toevoegen door deze te selecteren in het netwerk en de knoppen links te gebruiken."])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])}
      }
    },
    "onBoarding": {
      "goToTouchTable": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verhalen op een rij"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een afbeelding om te starten. Ga naar het netwerk en duik in de geschiedenis."])}
      },
      "fillStoryBasket": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je verhalen box"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg interessante afbeeldingen toe aan je verhalenbox om deze op te slaan, deze kan je dan later herbekijken in de box of op de website."])}
      },
      "lookAtOtherPictures": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk andere foto's"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer foto's in het netwerk om ze te bekijken en meer informatie te weten te komen."])}
      },
      "backToOverview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verhalen op een rij"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via deze knop kan je steeds terug naar het overzicht van de afbeeldingen van alle verhalen."])}
      },
      "buttons": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
        "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben er klaar voor!"])}
      }
    },
    "inactivityModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door inactiviteit wordt de touchtafel binnenkort afgesloten"])},
      "shutdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie nu afsluiten"])},
      "keepGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder gaan"])}
    }
  },
  "intro": {
    "screen-1": {
      "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom in de"])},
      "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoGent-box!"])},
      "info-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem hier je ticket"])},
      "info-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["om te starten."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder"])}
    },
    "screen-2": {
      "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een verhaal"])},
      "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sub-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een verhaal"])},
      "sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voor meer informatie"])},
      "button-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "button-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik kies dit verhaal"])}
    },
    "screen-3": {
      "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ticket wordt"])},
      "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geprint"])},
      "title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bent er helemaal"])},
      "title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klaar voor!"])},
      "button-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten en terug naar startscherm"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem je ticket.\nGa naar het grote scherm en scan je ticket aan de gele zuil.\nGeniet van het verhaal.\nGa erna nog even langs de tafel.\nVeel plezier!"])}
    }
  },
  "wall": {
    
  }
}