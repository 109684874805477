import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-Center text-text-white w-full font-bold text-3xl" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxAmountOfCharacters, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "p-3"
      }, [
        (_ctx.code[index - 1])
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.code[index - 1]), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, " . "))
      ]))
    }), 128))
  ]))
}