
  import { defineComponent } from 'vue';
  import { BaseButton, BaseIcon } from 'coghent-vue-3-component-library';
  import { useOnBoarding } from '@/composables/useOnBoarding';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'OnBoardingCard',
    components: { BaseButton, BaseIcon },
    props: {
      cardTitle: {
        type: String,
        required: true,
      },
      cardDescription: {
        type: String,
        required: true,
      },
      showPreviousButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      showNextButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      nextButtonText: {
        type: String,
        required: false,
        default: 'touchtable.onBoarding.buttons.next',
      },
      placement: {
        type: String,
        required: false,
        default: 'top',
      },
      distance: {
        type: Number,
        required: false,
      },
      showCard: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['previousButtonClicked', 'nextButtonClicked'],
    setup(props, { emit }) {
      const { onBoardingState, goToNextStep, goToPreviousStep, changeOnBoardingStatus } =
        useOnBoarding();
      const { t } = useI18n();

      const endOnBoarding = () => {
        changeOnBoardingStatus('ended');
      };

      const previousButtonFunction = () => {
        goToPreviousStep();
        emit('previousButtonClicked', true);
      };

      const nextButtonFunction = () => {
        goToNextStep();
        emit('nextButtonClicked', true);
      };

      return {
        previousButtonFunction,
        nextButtonFunction,
        endOnBoarding,
        t,
      };
    },
  });
