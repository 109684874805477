import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1a35246"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex justify-center overflow-x-hidden overlay" }
const _hoisted_2 = { class: "flex absolute p-4 justify-between w-full" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "w-1/4 text-center flex flex-wrap justify-center call-background"
}
const _hoisted_5 = { class: "mt-48 text-xl" }
const _hoisted_6 = { class: "text-4xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_the_masonry = _resolveComponent("the-masonry")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_overlay = _resolveComponent("base-overlay")!

  return (_ctx.BasketOverlayState.overlayItems)
    ? (_openBlock(), _createBlock(_component_base_overlay, {
        key: 0,
        "overlay-state": _ctx.BasketOverlayState.state,
        class: "p-24"
      }, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", _hoisted_1, [
              (
            _ctx.basketEntities.length == _ctx.BasketOverlayState.overlayItems.length &&
            _ctx.BasketOverlayState.overlayItems.length
          )
                ? (_openBlock(), _createBlock(_component_the_masonry, {
                    key: 0,
                    ref: "masonry",
                    entities: { results: _ctx.basketEntities },
                    loading: _ctx.loadingEntity,
                    "generate-url": _ctx.generateUrl,
                    "no-image-url": _ctx.noImageUrl,
                    "show-load-more": false,
                    hasCustomImageOverlay: true
                  }, {
                    tile: _withCtx(({ entity }) => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_base_button, {
                          class: "w-0",
                          "custom-style": "secondary-round",
                          "icon-shown": true,
                          "custom-icon": "fullscreen",
                          onClick: ($event: any) => (_ctx.openMediaOverlay(entity))
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_base_button, {
                          class: "w-0",
                          "custom-style": "secondary-round",
                          "icon-shown": true,
                          "custom-icon": "wasteBasket",
                          onClick: ($event: any) => (_ctx.removeFromBasket(entity.id))
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["entities", "loading", "generate-url", "no-image-url"]))
                : (_ctx.BasketOverlayState.overlayItems.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_spinner)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t('touchtable.network.basketOverlay.empty')), 1)
                    ]))
            ]),
            _createElementVNode("section", {
              class: "w-full flex justify-center flex-wrap items-center p-12 font-bold cursor-pointer flex-col",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeBasketOverlay && _ctx.closeBasketOverlay(...args)))
            }, [
              _createVNode(_component_base_icon, {
                icon: "downwardArrows",
                class: "transform rotate-180 mb-4"
              }),
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('touchtable.network.basketOverlay.close')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["overlay-state"]))
    : _createCommentVNode("", true)
}