import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "text-lg" }
const _hoisted_4 = { class: "flex justify-center items-center w-full mt-12" }
const _hoisted_5 = { class: "p-4" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_display = _resolveComponent("number-display")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _component_number_pad = _resolveComponent("number-pad")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_openBlock(), _createElementBlock("main", {
    class: "flex justify-center items-center h-screen w-screen bg-touchtable-dark",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideWrongCodeMessage()))
  }, [
    _createVNode(_component_CardComponent, {
      large: true,
      "background-color": "bg-accent-yellow",
      "rounds-color": "bg-touchtable-dark",
      class: "w-1/2"
    }, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_1, [
          _createVNode(_component_VDropdown, {
            shown: _ctx.displayWrongCodeMessage,
            placement: "right-start",
            "show-triggers": []
          }, {
            popper: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.t('touchtable.codeScreen.wrongCode.title')), 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('touchtable.codeScreen.wrongCode.description')), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_number_display, {
                code: _ctx.NumberPadState.state,
                "max-amount-of-characters": _ctx.maxAmountOfNumbers,
                class: "mb-12"
              }, null, 8, ["code", "max-amount-of-characters"])
            ]),
            _: 1
          }, 8, ["shown"]),
          _createVNode(_component_number_pad)
        ]),
        _createElementVNode("section", _hoisted_4, [
          _createVNode(_component_VDropdown, null, {
            popper: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.t('touchtable.codeScreen.noCodePopUp.title')), 1)
                ]),
                _createElementVNode("p", {
                  innerHTML: _ctx.t('touchtable.codeScreen.noCodePopUp.description')
                }, null, 8, _hoisted_7)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_base_button, {
                class: "text-lg underline",
                "custom-style": "touchtable-black",
                "icon-shown": false,
                text: _ctx.t('touchtable.codeScreen.noCode')
              }, null, 8, ["text"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}