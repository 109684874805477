import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cd9d9b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-4 text-lg onBoardingCard" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "text-2xl" }
const _hoisted_4 = { class: "flex justify-end p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_VDropdown = _resolveComponent("VDropdown")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    autoHide: false,
    shown: _ctx.showCard,
    triggers: [],
    placement: _ctx.placement,
    distance: _ctx.distance,
    shift: false
  }, {
    popper: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createElementVNode("b", null, _toDisplayString(_ctx.cardTitle), 1)
          ]),
          _createVNode(_component_base_icon, {
            icon: "close",
            class: "stroke-current fill-current stroke-2 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.endOnBoarding()))
          })
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.cardDescription), 1),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.showPreviousButton)
            ? (_openBlock(), _createBlock(_component_base_button, {
                key: 0,
                text: _ctx.t('touchtable.onBoarding.buttons.previous'),
                "custom-style": "touchtable-white-round",
                iconShown: false,
                class: "shadow mr-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.previousButtonFunction()))
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
          (_ctx.showNextButton)
            ? (_openBlock(), _createBlock(_component_base_button, {
                key: 1,
                text: _ctx.t(_ctx.nextButtonText),
                "custom-style": "touchtable-green-round",
                iconShown: false,
                class: "shadow",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextButtonFunction()))
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["shown", "placement", "distance"]))
}