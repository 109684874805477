
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import {
    CardComponent,
    BaseButton,
    GetBoxVisiterRelationsByTypeDocument,
    useBoxVisiter,
  } from 'coghent-vue-3-component-library';
  import NumberPad, { useNumberPad } from '@/components/NumberPad.vue';
  import NumberDisplay from '@/components/NumberDisplay.vue';
  import { useRouter } from 'vue-router';
  import { apolloClient } from '@/main';
  import { useTouchTable } from '@/composables/useTouchTable';
  import { useOnBoarding } from '@/composables/useOnBoarding';
  import { useI18n } from 'vue-i18n';
  import { useInactiveTimer } from '../composables/useInactiveTimer';

  export default defineComponent({
    name: 'StartCode',
    components: {
      CardComponent,
      NumberPad,
      NumberDisplay,
      BaseButton,
    },
    props: {},
    setup: (props) => {
      const { updateNumberPad, undoNumberPad, resetNumberPad, NumberPadState } =
        useNumberPad();
      const { initiateTimer } = useInactiveTimer();
      const maxAmountOfNumbers = 8;
      const router = useRouter();
      const displayWrongCodeMessage = ref<boolean>(false);
      const { updateIsFirstStoryOverview } = useTouchTable();
      const { resetOnBoardingState } = useOnBoarding();
      const { t } = useI18n();

      const showWrongCodeMessage = () => {
        displayWrongCodeMessage.value = true;
      };

      const hideWrongCodeMessage = () => {
        displayWrongCodeMessage.value = false;
      };

      watch(
        () => NumberPadState.value.state.length,
        () => {
          if (NumberPadState.value.state.length == maxAmountOfNumbers) {
            checkCode();
          }
        },
      );

      const checkCode = () => {
        let code: string = NumberPadState.value.state.join('');
        // code = '88890355'; // 81453243, 37898122, 15747469
        const { getByCode } = useBoxVisiter(apolloClient);
        const resolvedBoxVisit = getByCode(code);
        resolvedBoxVisit
          .then((boxVisit: any) => {
            if (boxVisit) {
              resetOnBoardingState();
              updateIsFirstStoryOverview(true);
              useBoxVisiter(apolloClient).addTouchTableTime(code);
              router.push('/touchtable/stories');
              initiateTimer();
              resetNumberPad();
            }
          })
          .catch(() => {
            showWrongCodeMessage();
            resetNumberPad();
          });
      };

      // checkCode();

      return {
        NumberPadState,
        maxAmountOfNumbers,
        checkCode,
        displayWrongCodeMessage,
        hideWrongCodeMessage,
        t,
      };
    },
  });
