import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fc208c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-24 py-8 flex justify-between items-center" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "text-4xl font-bold" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "bg-background-light h-auto min-h-screen p-24" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["id"]
const _hoisted_8 = {
  key: 1,
  class: "w-full flex justify-center align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inactivity_modal = _resolveComponent("inactivity-modal")!
  const _component_shutdown_modal = _resolveComponent("shutdown-modal")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_story_item = _resolveComponent("story-item")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_ctx.boxVisiter)
    ? (_openBlock(), _createElementBlock("div", {
        key: _ctx.boxVisiter.code
      }, [
        _createVNode(_component_inactivity_modal),
        _createVNode(_component_shutdown_modal, {
          code: _ctx.boxVisiter.code
        }, null, 8, ["code"]),
        _createElementVNode("nav", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('touchtable.header.storyOverviewTitle')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_button, {
              class: "shadow mr-8 text-xl",
              "custom-style": "touchtable-white-round",
              "custom-icon": "door",
              "icon-shown": true,
              text: _ctx.t('touchtable.header.buttons.shutdown'),
              onClick: _ctx.openShutdownModal
            }, null, 8, ["text", "onClick"]),
            _createVNode(_component_base_button, {
              class: "shadow text-xl",
              "custom-style": "touchtable-white-round",
              text: "?",
              "icon-shown": false,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restartOnBoarding()))
            })
          ])
        ]),
        _createElementVNode("main", _hoisted_5, [
          (!_ctx.loadingActiveBoxResult && _ctx.activeBoxResult && !_ctx.loadingCustomStory)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storyAssets, (storyAsset, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: storyAsset.id,
                    id: storyAsset.id
                  }, [
                    _createVNode(_component_story_item, {
                      story: storyAsset,
                      "story-number": index + 1,
                      "story-entities": storyAsset.frames,
                      "story-color": _ctx.colors[index] ? _ctx.colors[index].replace('bg-', '') : _ctx.colors[0],
                      loading: _ctx.loadingActiveBoxResult,
                      isLastSeenStory: _ctx.lastSeenStoryId == storyAsset.id,
                      lastStoryItem: index == _ctx.storyAssets.length ? true : false
                    }, null, 8, ["story", "story-number", "story-entities", "story-color", "loading", "isLastSeenStory", "lastStoryItem"])
                  ], 8, _hoisted_7))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_spinner)
              ]))
        ])
      ]))
    : _createCommentVNode("", true)
}