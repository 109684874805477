
  import { defineComponent, onMounted, ref } from 'vue';
  import { BaseIcon, BaseButton, useBoxVisiter } from 'coghent-vue-3-component-library';
  import { fabricdefaults } from '@/services/Fabric/defaults.fabric';
  import { useShutdownModal } from '@/components/ShutdownModal.vue';
  import { useBasketOverlay } from '@/components/BasketOverlay.vue';
  import { useRouter } from 'vue-router';
  import OnBoardingCard from '@/components/OnBoardingCard.vue';
  import { useOnBoarding } from '@/composables/useOnBoarding';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'TouchHeader',
    components: {
      BaseIcon,
      BaseButton,
      OnBoardingCard,
    },
    props: {
      basketAmount: {
        type: Number,
        default: 0,
        required: false,
      },
    },
    setup: (props) => {
      const { openShutdownModal, closeShutdownModal } = useShutdownModal();
      const { closeBasketOverlay, openBasketOverlay } = useBasketOverlay();
      const { onBoardingState } = useOnBoarding();
      const router = useRouter();
      const { t } = useI18n();

      const root = document.documentElement;
      root.style.setProperty(
        '--header_height',
        fabricdefaults.canvas.header.height.toString() + 'px',
      );

      const goToStoriesPage = () => {
        const { clearHistoryAssets } = useBoxVisiter();
        clearHistoryAssets();
        router.push('stories');
      };

      return {
        openShutdownModal,
        goToStoriesPage,
        openBasketOverlay,
        onBoardingState,
        t,
      };
    },
  });
