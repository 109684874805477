
  import { defineComponent, onMounted, ref, watch } from 'vue';

  export default defineComponent({
    name: 'NumberDisplay',
    components: {},
    props: {
      code: {
        type: Array,
        required: true,
      },
      maxAmountOfCharacters: {
        type: Number,
        required: true,
      },
    },
    setup: (props) => {},
  });
