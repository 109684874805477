import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full flex flex-col p-24" }
const _hoisted_2 = { class: "text-6xl font-bold text-center pb-24" }
const _hoisted_3 = { class: "text-5xl text-center" }
const _hoisted_4 = { class: "flex justify-center items-center pt-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    scroll: false,
    "modal-state": _ctx.InactivityModalState.state,
    onHideModal: _ctx.closeInactivityModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('touchtable.inactivityModal.title')), 1),
        _createElementVNode("p", _hoisted_3, "0:" + _toDisplayString(_ctx.timerState.timeLeft), 1),
        _createElementVNode("section", _hoisted_4, [
          _createVNode(_component_base_button, {
            class: "shadow mr-8",
            "custom-style": "touchtable-white-round",
            "icon-shown": true,
            text: _ctx.t('touchtable.inactivityModal.shutdown'),
            onClick: _ctx.logOut
          }, null, 8, ["text", "onClick"]),
          _createVNode(_component_base_button, {
            class: "shadow",
            "custom-style": "touchtable-green-round",
            "icon-shown": false,
            text: _ctx.t('touchtable.inactivityModal.keepGoing')
          }, null, 8, ["text"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-state", "onHideModal"]))
}