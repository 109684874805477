import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.containerStyles)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keys, (key, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(key.type == 'number' || key.type == 'icon' ? _ctx.keyStyles : _ctx.spacerStyles),
          onClick: ($event: any) => (_ctx.addCharacterToResultArray(key))
        }, [
          (key.type == 'number')
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(key.value), 1))
            : _createCommentVNode("", true),
          (key.type == 'icon')
            ? (_openBlock(), _createBlock(_component_base_icon, {
                key: 1,
                icon: key.value,
                class: "stroke-current stroke-2"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      }), 128))
    ], 2)
  ]))
}