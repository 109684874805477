
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute } from 'vue-router';

  export default defineComponent({
    name: 'App',
    setup: () => {
      const route = useRoute();
      const { t } = useI18n();

      // Disable rightclick
      document.addEventListener('contextmenu', (event) => event.preventDefault());

      return { route, t };
    },
  });
