
  import {
    connectToClient,
    doPublish,
    doSubscribe,
    setWallIsPlaying,
    wallIsPlaying,
  } from '@/composables/useMqtt';
  import Colors from '@/Three/defaults.color';
  import { useQuery } from '@vue/apollo-composable';
  import { GetActiveBoxDocument } from 'coghent-vue-3-component-library';
  import { defineComponent, onMounted, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import Spinner from '@/components/Spinner.vue';
import { titleSelectedStory } from '@/composables/useStory';

  export default defineComponent({
    name: 'TouchinterfacePlaying',
    components: { Spinner },
    props: {},
    setup: (props) => {
      const router = useRouter();
      const route = useRoute();
      const stories = ref<any[]>([]);
      const colors = Colors().storyCss();
      const { loading, onResult, refetch } = useQuery(GetActiveBoxDocument, {
        id: route.query.id,
      });

      const resetWall = () => {
        doPublish('story/has-started', 'false');
        doPublish('story/start', JSON.stringify({ storyboxId: '', storyId: '' }));
        router.push({ name: 'touchinterface' });
      };

      const navigateHome = () => {
        router.push({ name: 'touchinterface' });
      };

      const changeWallStory = (story: any) => {
        if (wallIsPlaying.value === false) {
          doPublish(
            'story/start',
            JSON.stringify({ storyboxId: route.query.id, storyId: story.id }),
          );
          wallIsPlaying.value = true;
          titleSelectedStory.value = story.title[0].value
        }
      };

      const changeStoryOrder = (storyArray: any[]): any[] => {
        const returnArray: any[] = [];
        if (storyArray.length <= 2) {
          return storyArray;
        }
        returnArray[0] = storyArray[2];
        returnArray[1] = storyArray[0];
        returnArray[2] = storyArray[1];
        if (storyArray[3]) {
          returnArray[3] = storyArray[3];
        }
        return returnArray;
      };

      onResult((queryResult) => {
        const storiesInBox = queryResult.data.ActiveBox.results;
        if (storiesInBox) {
          const orderedStories = changeStoryOrder(storiesInBox);
          stories.value = orderedStories;
        }
      });

      onMounted(() => {
        connectToClient();
        doSubscribe('story/go-back', navigateHome);
        doSubscribe('story/has-started', setWallIsPlaying);
        refetch();
      });

      return { resetWall, loading, colors, stories, wallIsPlaying, changeWallStory, titleSelectedStory };
    },
  });
