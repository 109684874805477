
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import ViewPort from '@/components/ViewPort.vue';
  import { useQuery } from '@vue/apollo-composable';
  import StoryService from '@/services/StoryService';
  import { FlowState } from '@/services/StateService';
  import Common, { SensorObject } from '@/composables/common';
  import useCustomStory from '@/composables/useCustomStory';
  import useFlow from '@/composables/flows';
  import { GetActiveBoxDocument, RelationType } from 'coghent-vue-3-component-library';
  import { useBoxVisiter } from 'coghent-vue-3-component-library';
  import { apolloClient } from '@/main';
  import { Relation } from 'coghent-vue-3-component-library/lib/queries';
  import { getFirstStoryToSee, getUnseenStories } from '@/composables/useBox';
  import Defaults from '@/Three/defaults.config';
  import stateService from '@/services/StateService';
  import Videos from '@/Three/defaults.videos';
  import VideoHelper from '@/Three/helper.video';
  import { Vector3 } from 'three';
  import globals from '@/services/GlobalData';
  import { useStorybox } from 'coghent-vue-3-component-library';
  import scenery from '@/composables/useScenery';
  import PrepareWall from '@/composables/prepareWall';
  import {
    doPublish,
    mqttEnabled,
    selectedStory,
    selectedStoryBoxId,
    selectedStoryId,
    wallInit,
    wallIsPlaying,
  } from '@/composables/useMqtt';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'Wall',
    components: { ViewPort },
    setup() {
      //@ts-ignore
      document.body.style.backgroundColor = 'black';
      let stories = ref<Array<any>>();
      const storyService = ref<StoryService | null>();
      const visitercode = ref<string | null>(null);
      const visiter = ref<any | null>(null);
      const inputValue = ref<string>('');
      const currentState = ref<string>(FlowState[0]);
      const showPauseOverview = ref<boolean>(false);
      const isCustomStory = ref<boolean>(false);
      const { prepareStory, prepareStories } = PrepareWall();
      const qrInput = ref<any>(null);
      window.addEventListener('focus', () => {
        if (qrInput.value) qrInput.value.focus();
      });
      const showInputField = ref<boolean>(false);
      const router = useRouter();

      selectedStoryBoxId.value =
        Common().getUrlParamValue('box') || window.localStorage.getItem('boxId') || '';
      const { onResult, fetchMore, refetch } = useQuery(
        GetActiveBoxDocument,
        { id: selectedStoryBoxId.value },
        { fetchPolicy: 'cache-first' },
      );

      watch(
        () => mqttEnabled.value,
        (value) => {
          toggleShowInputField(value as boolean);
        },
      );

      const toggleShowInputField = (_mqttEnabled: boolean) => {
        showInputField.value = !_mqttEnabled;
      };

      onResult(async (_stories) => {
        // prepareStories(_stories.data.ActiveBox.results);
        stories.value = _stories.data.ActiveBox.results;
        stateService.canScanTicket = true;
        const boxvisiter =
            JSON.parse(window.localStorage.getItem('visitorCode') as string)
        if (boxvisiter){
          inputValue.value = boxvisiter.code;
        }
      });

      watch(
        () => stateService.canScanTicket,
        (value: boolean) => {
          if (value === true) {
            doPublish('story/has-started', 'false');
          }
        },
      );

      const setVisiterData = async () => {
        useFlow().setCurrent('normal');
        showPauseOverview.value = false;
        if (stateService.canScanTicket) {
          const storyRelations = (await useBoxVisiter(apolloClient).getRelationsByType(
            visitercode.value,
            RelationType.Stories,
          )) as Array<Relation>;
          const tmpStoryService = createTempStoryService(storyRelations);
          const storiesToSee = getUnseenStories(
            storyRelations.map((_relation) =>
              tmpStoryService.getStoryDataOfStory(_relation.key.replace('entities/', '')),
            ),
          );
          tmpStoryService.hasScannedCode = tmpStoryService.hasAlreadyScannedCode();
          if (storiesToSee.length != 0) {
            stateService.canScanTicket = false;
            const storyToSet = getFirstStoryToSee(storiesToSee);
            if (storyToSet) {
              tmpStoryService.setActiveStory(storyToSet.storyId);
              storyService.value = tmpStoryService;
            }
          } else {
            const storiesSeen = storyRelations.map((_rel) =>
              _rel.key.replace('entities/', ''),
            ) as Array<string>;
            let storyToSetActive = null;
            stories.value?.forEach((_story) => {
              if (!storiesSeen.includes(_story.id)) {
                storyToSetActive = _story.id as string;
              } else {
                stateService.canScanTicket = true;
                console.log(`INFO | no stories to see on this ticket`);
              }
            });
            if (storyToSetActive) {
              stateService.changeState(FlowState.storyOverview);
              tmpStoryService.setActiveStory(storyToSetActive);
              showPauseOverview.value = true;
              storyService.value = tmpStoryService;
            }
          }
        }
        stateService.allStateStatus();
      };

      const setCustomStoryData = async (_visiterByCode: any, _storyId: string) => {
        useFlow().setCurrent('customStory');
        stateService.changeState(FlowState.generateStory);
        scenery.generateStoryScene();
        let storydata = await useStorybox(apolloClient).getStoryData(_storyId);
        storydata = await prepareStory(storydata);
        const tmpStoryService = new StoryService(
          [storydata] as Array<any>,
          visiter.value,
        );
        tmpStoryService.fillUpDataSources();
        tmpStoryService.setActiveStory(_storyId);
        tmpStoryService.activeStory = await useCustomStory(
          stories.value!,
          _visiterByCode,
        ).setTitleOfFrameAsStoryTitle(tmpStoryService.activeStory);
        tmpStoryService.hasScannedCode = true;
        await useBoxVisiter(apolloClient).updateScannedTimesOfVisiter(
          _visiterByCode.code,
        );
        storyService.value = tmpStoryService;
      };

      const createTempStoryService = (_storyRelations: Array<Relation>) => {
        const tmpStoryService = new StoryService(
          stories.value as Array<any>,
          visiter.value,
        );
        tmpStoryService.fillUpDataSources();
        tmpStoryService.mergeVisiterStoryRelationsWithStoryData(_storyRelations);
        return tmpStoryService;
      };

      watch(inputValue, (value: string) => {
        let code = Common().getCodeFromString(value);
        console.log('Code from inputfield', code);
        if (showInputField.value && value.length === 8) {
          code = value;
        }
        if (stateService.canScanTicket && code && code.length === 8) {
          console.log('code:', code);
          getCode(code);
          inputValue.value = '';
        }
        // inputValue.value = '';
      });

      const getCode = async (code: string) => {
        if (
          stories.value != null &&
          stateService.getCurrentState() != FlowState[1] &&
          stateService.getCurrentState() != FlowState[2] &&
          stateService.getCurrentState() != FlowState[3] &&
          stateService.getCurrentState() != FlowState[4] &&
          stateService.canScanTicket === true
        ) {
          console.log('Getting visiter with code:', code);
          currentState.value = stateService.getCurrentState();
          storyService.value = null;
          const visiterByCode = await useBoxVisiter(apolloClient).getByCode(String(code));
          if (visiterByCode.code) {
            visiter.value = visiterByCode;
            visitercode.value = String(code);
            const customStory = useCustomStory(stories.value, visiterByCode);
            isCustomStory.value = await customStory.isCustom();
            const customStoryId = await customStory.getStoryId();
            wallIsPlaying.value = true;
            isCustomStory.value === true
              ? await setCustomStoryData(visiterByCode, customStoryId)
              : await setVisiterData();
          }
        } else {
          stateService.changeState(FlowState.storyOverview);
          currentState.value = stateService.getCurrentState();
        }
      };

      const resetSelectedStory = (_resetTo: SensorObject) =>
        (selectedStory.value = _resetTo);

      window.onkeydown = async (key: KeyboardEvent) => {
        if (
          Defaults().keyboardSelect() ||
          (showInputField.value && stateService.canChooseNextStory)
        ) {
          switch (key.code) {
            case 'Digit1' || '97':
              console.log('pressed 1');
              selectedStory.value = {
                topic: 'sensors/1/present',
                id: 1,
                msg: true,
              };
              break;
            case 'Digit2' || '98':
              console.log('pressed 2');
              selectedStory.value = {
                topic: 'sensors/2/present',
                id: 2,
                msg: true,
              };
              break;
            case 'Digit3' || '99':
              console.log('pressed 3');
              selectedStory.value = {
                topic: 'sensors/3/present',
                id: 3,
                msg: true,
              };
              break;
            case 'Digit4' || '100':
              console.log('pressed 4');
              selectedStory.value = {
                topic: 'sensors/4/present',
                id: 4,
                msg: true,
              };
              break;
            case 'Digit5' || '101':
              console.log('pressed 5');
              selectedStory.value = {
                topic: 'sensors/1/instant',
                id: 1,
                msg: true,
              };
              break;
            case 'Digit6' || '102':
              console.log('pressed 6');
              selectedStory.value = {
                topic: 'sensors/2/instant',
                id: 2,
                msg: true,
              };
              break;
            case 'Digit7' || '103':
              console.log('pressed 7');
              selectedStory.value = {
                topic: 'sensors/3/instant',
                id: 3,
                msg: true,
              };
              break;
            case 'Digit8' || '104':
              console.log('pressed 8');
              selectedStory.value = {
                topic: 'sensors/4/instant',
                id: 4,
                msg: true,
              };
              break;
          }
        }
      };

      const setSelectStory = (sensorValue: {
        topic: string;
        id: number;
        msg: boolean;
      }) => {
        console.log(`MQTT data => `, sensorValue);
        // if (sensorValue.topic.includes('present') && sensorValue.msg) {
        let selected = null;
        switch (Number(sensorValue.id)) {
          case 1:
            selected = 4;
            break;
          case 2:
            selected = 2;
            break;
          case 3:
            //center
            selected = 0;
            break;
          case 4:
            selected = 1;
            break;
          case 5:
            selected = 3;
            break;
        }
        if (selected && stateService.canChooseNextStory) {
          selectedStory.value = {
            topic: sensorValue.topic,
            id: selected,
            msg: sensorValue.msg,
          };
        }

        console.log(`sensorvalue data => `, selected);
        // }
        //5-4-3-2-1
      };

      onMounted(async () => {
        wallInit();
        globals.startVideoElement = VideoHelper().videoElementAsCube(
          Videos.startVideoId,
          Videos.startOfSession,
          new Vector3(1920, 1080, 0),
          new Vector3(0, 0, 0),
        );
          globals.menuVideoElement = VideoHelper().videoElementAsCube(
            Videos.menuVideoId,
            Videos.selectStory,
            new Vector3(1280, 720, 0),
            new Vector3(0, -90, 0),
          );
          globals.menuVideoElement.scale.set(0.4, 0.4, 1);
      });

      return {
        qrInput,
        stories,
        setSelectStory,
        storyService,
        resetSelectedStory,
        inputValue,
        showPauseOverview,
        stateService,
        currentState,
        toggleShowInputField,
        showInputField,
        selectedStory,
        selectedStoryBoxId,
      };
    },
  });
