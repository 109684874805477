
  import { defineComponent, inject, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useBoxVisiter } from 'coghent-vue-3-component-library';
  import Colors from '@/Three/defaults.color';
  import { apolloClient } from '@/main';
  import { useQuery } from '@vue/apollo-composable';
  import { PrintBoxTicketDocument } from 'coghent-vue-3-component-library';
  import useTicket from '@/composables/useTicket';
  import Development from '@/Three/defaults.development';
  import { useI18n } from 'vue-i18n';
  import { useInactiveTimer } from '../composables/useInactiveTimer';

  export default defineComponent({
    name: 'Introscherm3',
    components: {},
    setup(props) {
      const { t } = useI18n();
      const router = useRouter();
      let currentVisiter: any = null;
      const { print } = useTicket();
      const { selectedStory } = useBoxVisiter(apolloClient);
      const colors = Colors().storyCssOnlyColor();
      const showPopup = inject<any>('showCodePopup');
      const { overwriteTimerSettings, initiateTimer } = useInactiveTimer();

      const getNumberByColor = (color: string) => {
        const index = colors.findIndex((element) => color === element);
        return index !== undefined ? index + 1 : 'nan';
      };

      const { fetchMore: printNewTicket } = useQuery(PrintBoxTicketDocument, {
        code: '',
      });

      useBoxVisiter(apolloClient)
        .create(`entities/${selectedStory.value.id}`)
        .then(async (visiter: any) => {
          currentVisiter = visiter;
          if (currentVisiter.code) {
            printTicket();
          }
          if (Development().showVisiterCodePopUp() || showPopup.value !== null) {
            alert(visiter.code);
          }
        });

      const printTicket = async () => {
        const ticket = await printNewTicket({
          variables: { code: currentVisiter.code } as any,
        });
        console.log({ ticket });
        try {
          await print(ticket?.data.PrintBoxTicket.body as string);
        } catch (error) {
          console.log('Print service offline.');
        }
      };

      const naarStart = () => {
        router.push({ name: 'entrance.step1' });
      };

      onMounted(() => {
        overwriteTimerSettings({
          timerSeconds: 30,
          showModalTime: 0,
          enableActivityTracker: false,
          timerFunction: naarStart,
        });
        initiateTimer();
      });

      return { t, naarStart, selectedStory, getNumberByColor, showPopup };
    },
  });
