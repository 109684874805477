
  import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { CardComponent, GetActiveBoxDocument } from 'coghent-vue-3-component-library';
  import { Entity } from 'coghent-vue-3-component-library/lib/queries';
  import { useQuery } from '@vue/apollo-composable';
  import { useBoxVisiter } from 'coghent-vue-3-component-library';
  import Colors from '@/Three/defaults.color';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'Introscherm2',
    setup() {
      const router = useRouter();
      const { t } = useI18n();
      const selectedIndex = ref();
      const { setSelectedStory } = useBoxVisiter();
      const colors = Colors().storyCssOnlyColor();
      const description = ref<string | false>(false);

      const selectStory = async (_entity: any, index: number) => {
        selectedIndex.value = index;
        const findDescription = _entity.metadata.find(
          (value: any) => value.key === 'description',
        );
        console.log(findDescription);
        description.value = findDescription ? findDescription.value : false;
      };

      const nextStep = async (_entity: any, index: number) => {
        let title = '';
        if (_entity.title) {
          title = _entity.title[0]?.value as string;
        }
        setSelectedStory({
          id: _entity.id,
          color: colors[index],
          title: title,
        });

        router.push({ name: 'entrance.step3' });
      };

      const stories = inject<any>('stories');

      const prevStep = () => {
        router.push({ name: 'entrance.step1' });
      };

      return {
        t,
        stories,
        colors,
        nextStep,
        prevStep,
        selectStory,
        selectedIndex,
        description,
      };
    },
  });
