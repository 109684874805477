
  import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
  import { fabricdefaults } from '@/services/Fabric/defaults.fabric';
  import { FilterTag } from 'coghent-vue-3-component-library';
  import { Relation } from 'coghent-vue-3-component-library/lib/queries';

  export default defineComponent({
    name: 'RelationBrowser',
    components: {
      FilterTag,
    },
    props: {
      relations: {
        type: Array as PropType<Relation[]>,
        required: true,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['selected'],
    setup: (props, { emit }) => {
      const selectedTagIndex = ref<number>();
      const root = document.documentElement;
      root.style.setProperty(
        '--browser_height',
        fabricdefaults.canvas.relationBrowser.height.toString() + 'px',
      );

      const selectTag = (tagIndex: number) => {
        if (selectedTagIndex.value == tagIndex) {
          selectedTagIndex.value = undefined;
        } else {
          selectedTagIndex.value = tagIndex;
        }
        emit(
          'selected',
          selectedTagIndex.value != undefined
            ? props.relations[selectedTagIndex.value]
            : selectedTagIndex.value,
        );
      };

      return { selectTag, selectedTagIndex };
    },
  });
