
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import {
    BaseModal,
    BaseButton,
    useBoxVisiter,
    BoxVisitCode,
  } from 'coghent-vue-3-component-library';
  import { apolloClient } from '@/main';
  import { useRoute, useRouter } from 'vue-router';

  export type ModalState = 'show' | 'hide' | 'loading';

  export type ShutdownModalType = {
    state: ModalState;
  };

  const ShutdownModalState = ref<ShutdownModalType>({
    state: 'hide',
  });

  export const useShutdownModal = () => {
    const updateShutdownModal = (ShutdownModalInput: ShutdownModalType) => {
      ShutdownModalState.value = ShutdownModalInput;
    };

    const closeShutdownModal = () => {
      updateShutdownModal({
        state: 'hide',
      });
    };

    const openShutdownModal = () => {
      updateShutdownModal({
        state: 'show',
      });
    };

    return {
      closeShutdownModal,
      openShutdownModal,
      ShutdownModalState,
    };
  };

  export default defineComponent({
    name: 'ShutdownModal',
    components: {
      BaseModal,
      BaseButton,
      BoxVisitCode,
    },
    props: {
      code: {
        type: String,
        required: true,
      },
    },
    emits: ['disposeCanvas'],
    setup: (props, { emit }) => {
      const { openShutdownModal, closeShutdownModal, ShutdownModalState } =
        useShutdownModal();
      const router = useRouter();

      const goToCodeScreen = () => {
        const { resetBoxVisiter } = useBoxVisiter(apolloClient);
        resetBoxVisiter();
        closeShutdownModal();
        emit('disposeCanvas', true);
        window.location.href = '/touchtable/start';
      };

      const goToStoriesScreen = () => {
        const { clearHistoryAssets } = useBoxVisiter();
        clearHistoryAssets();
        closeShutdownModal();
        emit('disposeCanvas', true);
        router.push('/touchtable/stories');
      };

      return {
        ShutdownModalState,
        closeShutdownModal,
        goToCodeScreen,
        goToStoriesScreen,
      };
    },
  });
