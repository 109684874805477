
  import { useQuery } from '@vue/apollo-composable';
  import { defineComponent, onMounted, ref } from 'vue';
  import {
    GetBoxesDocument,
    GetEntitiesByIdsDocument,
  } from 'coghent-vue-3-component-library';
  import Spinner from '@/components/Spinner.vue';
  import {
    connectToClient,
    doPublish,
    wallIsPlaying,
  } from '@/composables/useMqtt';
  import Colors from '@/Three/defaults.color';
  import { useRouter } from 'vue-router';
import { titleSelectedStory } from '@/composables/useStory';

  type SelectedStory = {
    storyId: string;
    storyboxId: string;
    storyColor: string;
  };

  type BoxWithStoryIds = {
    boxId: string;
    boxTitle: string;
    storyIds: string[];
    stories?: any[];
  };

  export default defineComponent({
    name: 'Touchinterface',
    components: { Spinner },
    props: {},
    setup: (props) => {
      const storyItems = ref<BoxWithStoryIds[]>([]);
      const storyboxes = ref<any[]>([]);
      const storyboxesWithStories = ref<any[]>([]);
      let colors = Colors().storyCss();
      const selectedStoryInfo = ref<SelectedStory>({
        storyboxId: '',
        storyId: '',
        storyColor: '',
      });
      const router = useRouter();
      const { loading, onResult, refetch } = useQuery(GetBoxesDocument);

      const getAllStoryIds = (): string[] => {
        const ids: string[] = [];
        storyItems.value.forEach((item: BoxWithStoryIds) => {
          ids.push(...item.storyIds);
        });
        return ids;
      };

      const {
        onResult: onStoriesResult,
        refetch: refetchStories,
        loading: loadingStories,
      } = useQuery(GetEntitiesByIdsDocument, {
        ids: getAllStoryIds(),
      });

      const findBoxFromStory = (storyId: string) => {
        const box = storyboxes.value.find((box: any) =>
          box.relations.find((rel: any) => rel.key.includes(storyId)),
        );
        return box;
      };

      const listenToClick = (story: any, color: string) => {
        setSelectedStory(story.id, color);
        changeWallStory();
        titleSelectedStory.value = story.title[0].value
      };

      const setSelectedStory = (storyId: string, color: string) => {
        const box = findBoxFromStory(storyId);
        selectedStoryInfo.value = {
          storyboxId: box.id,
          storyId: storyId,
          storyColor: color,
        };
      };

      const resetWall = () => {
        console.log('here')
        doPublish('wall/reset', JSON.stringify({}));
      }

      const changeWallStory = () => {
        doPublish('story/start', JSON.stringify(selectedStoryInfo.value));
        wallIsPlaying.value = true;
        router.push({
          name: 'touchinterface.playing',
          query: { id: selectedStoryInfo.value.storyboxId },
        });
      };

      const filterDuplicateIds = (array: BoxWithStoryIds[]) => {
        const tempArray: BoxWithStoryIds[] = [];
        array.forEach((box: BoxWithStoryIds) => {
          if (!tempArray.find((item: BoxWithStoryIds) => item.boxId === box.boxId)) {
            tempArray.push(box);
          }
        });
        return tempArray;
      };

      onResult((queryResult) => {
        storyboxes.value = queryResult.data.GetBoxes.results;
        queryResult.data.GetBoxes.results.map((res: any) => {
          storyItems.value.push({
            boxId: res.id,
            boxTitle: res.title[0].value,
            storyIds: res.relations
              .filter((rel: any) => rel.type === 'box_stories')
              .map((entity: any) => entity.key),
          });
        });
        storyItems.value = filterDuplicateIds(storyItems.value);
        refetchStories({
          ids: getAllStoryIds(),
        });
      });

      const getStoryColors = (amount: number): string[] => {
        return colors;
      };

      const changeStoryOrder = (storyArray: any[]): any[] => {
        const returnArray: any[] = [];
        if (storyArray.length <= 2) {
          return storyArray;
        }
        returnArray[0] = storyArray[2];
        returnArray[1] = storyArray[0];
        returnArray[2] = storyArray[1];
        if (storyArray[3]) {
          returnArray[3] = storyArray[3];
        }
        return returnArray;
      };

      onStoriesResult((queryResult) => {
        if (queryResult.data) {
          const boxes = storyItems.value.map((item) => {
            const storyIds = changeStoryOrder(item.storyIds) ;
            const storyColors = getStoryColors(storyIds.length);
            storyIds.forEach((id, index) => {
              const foundEntity = queryResult.data.GetEntitiesByIds.find(
                (entity: any) => entity.id === id.replace('entities/', ''),
              );
              if (!item.stories) item.stories = [];
              if (
                foundEntity &&
                !item.stories.find((story: any) => story.id === foundEntity.id)
              ) {
                const coloredEntity = {
                  ...foundEntity,
                  color: storyColors[index],
                };
                item.stories?.push(coloredEntity);
              }
            });
            return item;
          });
          storyboxesWithStories.value = boxes;
        }
      });

      onMounted(() => {
        connectToClient();
        refetch();
      });

      return {
        storyboxesWithStories,
        changeWallStory,
        loading,
        loadingStories,
        setSelectedStory,
        selectedStoryInfo,
        listenToClick,
        resetWall
      };
    },
  });
