
  import { defineComponent, nextTick, onMounted, onUpdated, ref, watch } from 'vue';
  import { TheMasonry } from 'coghent-vue-3-component-library';
  import { useBoxVisiter, BaseIcon } from 'coghent-vue-3-component-library';
  import { apolloClient } from '@/main';
  import { useRouter } from 'vue-router';
  import { Entity, Relation } from 'coghent-vue-3-component-library/lib/queries';
  import { iiiF } from '@/main';
  import { useTouchTable } from '@/composables/useTouchTable';
  import { useOnBoarding } from '@/composables/useOnBoarding';
  import OnBoardingCard from '@/components/OnBoardingCard.vue';
  import { useI18n } from 'vue-i18n';
  import { from } from '@apollo/client/core';

  type EntityData = {
    results: any[];
  };

  export default defineComponent({
    name: 'StoryItem',
    components: { TheMasonry, BaseIcon, OnBoardingCard },
    props: {
      story: {
        type: Object,
        required: true,
      },
      storyNumber: {
        type: Number,
        required: true,
      },
      storyColor: {
        type: String,
        required: true,
      },
      storyEntities: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      lastStoryItem: {
        type: Boolean,
        required: true,
        default: false,
      },
      isLastSeenStory: {
        type: Boolean,
        required: false,
      },
    },
    setup(props) {
      const { generateUrl, noImageUrl } = iiiF;
      const masonry = ref<any>(null);
      const entityData = ref<EntityData>({ results: [] });
      const { boxVisiter, setStartAsset, setSelectedStory } = useBoxVisiter(apolloClient);
      const boxVisiterStories = boxVisiter.value.relations.find(
        (relation: any) => relation.type === 'stories',
      );
      const router = useRouter();
      const { updateIsFirstStoryOverview } = useTouchTable();
      const onBoardingEntityId = ref<string>();
      const { onBoardingState } = useOnBoarding();
      const { t } = useI18n();

      const filterVisitedRelationsFromBoxVisiter = (relations: Relation[]): string[] => {
        const visitedRelations = relations
          .filter((relation: Relation) => relation.type == 'visited')
          .map((relation: Relation) => relation.key.replace('entities/', ''));
        return visitedRelations as string[];
      };

      const tempAssetArray: any[] = [];
      props.storyEntities.forEach((frame: any, frameIndex: number) => {
        const visitedAssets = filterVisitedRelationsFromBoxVisiter(
          boxVisiter.value.relations,
        );
        try {
          let isFrameSeen: Boolean = false;
          boxVisiterStories.seen_frames.forEach((seenFrame: any) => {
            if (seenFrame.id == frame.id) {
              isFrameSeen = true;
            }
          });
          const frameAssets = frame.assets.map((asset: any, index: number) => {
            if (index == 0 && frameIndex == 0) {
              onBoardingEntityId.value = asset.id;
            }
            const newAsset = { ...asset };
            newAsset.seen =
              isFrameSeen || visitedAssets.includes(asset.id) ? true : false;
            return newAsset;
          });
          tempAssetArray.push(...entityData.value.results.concat(frameAssets));
        } catch (e) {
          if (frame.assets) {
            console.log(`No seen frames, looking for visited assets instead`);
            const frameAssets = frame.assets.map((asset: any, index: number) => {
              if (index == 0 && frameIndex == 0) {
                onBoardingEntityId.value = asset.id;
              }
              const newAsset = { ...asset };
              newAsset.seen = visitedAssets.includes(asset.id) ? true : false;
              return newAsset;
            });
            tempAssetArray.push(...entityData.value.results.concat(frameAssets));
          }
        }
      });

      entityData.value.results = tempAssetArray;

      const handleTouchtableNavigation = (
        entity: Entity,
        fromOnboarding: boolean = false,
      ) => {
        updateIsFirstStoryOverview(false);
        setStartAsset(entity);
        setSelectedStory({
          id: props.story.id,
          color: props.storyColor,
          title: props.story.title[0].value,
        });
        if (fromOnboarding) {
          router.push('/touchtable/' + entity.id);
        }
      };

      return {
        entityData,
        generateUrl,
        noImageUrl,
        masonry,
        router,
        handleTouchtableNavigation,
        onBoardingEntityId,
        onBoardingState,
        t,
      };
    },
  });
