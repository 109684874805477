import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e163183"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "browser",
  class: "relation_browser_container no-scrollbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterTag = _resolveComponent("FilterTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relations, (item, index) => {
      return (_openBlock(), _createBlock(_component_FilterTag, {
        key: index,
        filter: item.value,
        "is-selected": index == _ctx.selectedTagIndex ? true : false,
        icon: 'check',
        small: false,
        loading: _ctx.loading,
        onClick: ($event: any) => (_ctx.selectTag(index))
      }, null, 8, ["filter", "is-selected", "loading", "onClick"]))
    }), 128))
  ]))
}