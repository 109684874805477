
  import { defineComponent, ref } from 'vue';
  import { useInactiveTimer, logOut } from '../composables/useInactiveTimer';
  import { BaseModal, BaseButton } from 'coghent-vue-3-component-library';
  import { useI18n } from 'vue-i18n';

  export type ModalState = 'show' | 'hide' | 'loading';

  export type InactivityModalType = {
    state: ModalState;
  };

  const InactivityModalState = ref<InactivityModalType>({
    state: 'hide',
  });

  export const useInactivityModal = () => {
    const updateInactivityModal = (InactivityModalInput: InactivityModalType) => {
      InactivityModalState.value = InactivityModalInput;
    };

    const closeInactivityModal = () => {
      updateInactivityModal({
        state: 'hide',
      });
    };

    const openInactivityModal = () => {
      updateInactivityModal({
        state: 'show',
      });
    };

    return {
      closeInactivityModal,
      openInactivityModal,
      InactivityModalState,
    };
  };

  export default defineComponent({
    name: 'InactivityModal',
    components: { BaseModal, BaseButton },
    setup() {
      const { closeInactivityModal, InactivityModalState } = useInactivityModal();
      const { timerState } = useInactiveTimer();
      const { t } = useI18n();

      return { closeInactivityModal, InactivityModalState, timerState, logOut, t };
    },
  });
